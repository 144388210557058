import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  padding: 12.5rem 10vw 5.625rem;

  @media (max-width: 1199px) {
    padding: 7.5rem 1.875rem 3.75rem;
  }
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
  margin-top: 1.875rem;

  a {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 0.875rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark3};
        border-radius: 50%;
        position: absolute;
        top: 0.9375rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    margin-top: 1.4375rem;
  }
`
